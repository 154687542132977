'use client';

import { useEffect, useCallback } from 'react';

import { ErrorPage } from '@/components/error-page';
import type { Translations } from '@/types';

interface ClientGlobalErrorProps {
    error: Error & { digest?: string };
    translations: Translations;
}

export function ClientGlobalError({
    error,
    translations,
}: ClientGlobalErrorProps) {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return;

        const reportError = async () => {
            const Sentry = await import('@sentry/nextjs');
            Sentry.captureException(error);
        };

        reportError().catch(console.error);
    }, [error]);

    const handleReload = useCallback(() => window.location.reload(), []);

    return (
        <ErrorPage
            title={translations.error.default.title}
            description={translations.error.default.description}
            actionLabel={translations.error.default.retry}
            onAction={handleReload}
            translations={translations}
        />
    );
}
