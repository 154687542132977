'use client';

import { Suspense, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';

import { ClientGlobalError } from '@/components/client-global-error';
import { useTranslations } from '@/hooks/useTranslations';
import { LOCALES } from '@/i18n/config';
import type { Translations } from '@/types';

const StructuredData = dynamic(() =>
    import('@/components/structured-data').then((mod) => ({
        default: mod.StructuredData,
    }))
);

const inter = Inter({
    subsets: ['latin'],
    display: 'swap',
    preload: true,
    fallback: ['system-ui', 'arial'],
    adjustFontFallback: true,
});

const ColorSchemeScript = () => (
    <script
        dangerouslySetInnerHTML={{
            __html: `
                (() => {
                    try {
                        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
                        const isDark = darkModeMediaQuery.matches;
                        document.documentElement.classList.toggle('dark', isDark);
                        
                        darkModeMediaQuery.addEventListener('change', (e) => {
                            document.documentElement.classList.toggle('dark', e.matches);
                        });

                        document.documentElement.style.visibility = 'visible';
                    } catch (e) {
                        console.error('Failed to initialize color scheme:', e);
                    }
                })();
            `,
        }}
    />
);

interface GlobalErrorProps {
    error: Error & { digest?: string };
}

export default function GlobalError({ error }: GlobalErrorProps) {
    const translations = useTranslations();
    const [url, setUrl] = useState<string>('');
    const [locale, setLocale] = useState<string>('en');

    useEffect(() => {
        setUrl(window.location.href);
        setLocale(document.documentElement.lang || 'en');

        document.documentElement.classList.add(inter.className);
    }, []);

    return (
        <html
            lang={locale}
            className={inter.className}
            suppressHydrationWarning
        >
            <head>
                <title>{translations.error.default.title} | Thumbgrab</title>
                <meta
                    name="description"
                    content={translations.error.default.description}
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />

                <meta
                    property="og:type"
                    content="website"
                />
                <meta
                    property="og:site_name"
                    content="Thumbgrab"
                />
                <meta
                    property="og:title"
                    content={translations.error.default.title}
                />
                <meta
                    property="og:description"
                    content={translations.error.default.description}
                />
                <meta
                    property="og:url"
                    content={url}
                />
                <meta
                    property="og:image"
                    content="/og-image.png"
                />
                <meta
                    property="og:image:width"
                    content="1200"
                />
                <meta
                    property="og:image:height"
                    content="630"
                />

                <meta
                    name="twitter:card"
                    content="summary_large_image"
                />
                <meta
                    name="twitter:title"
                    content={translations.error.default.title}
                />
                <meta
                    name="twitter:description"
                    content={translations.error.default.description}
                />
                <meta
                    name="twitter:image"
                    content="/twitter-image.png"
                />

                <link
                    rel="canonical"
                    href={url}
                />
                {Object.entries(LOCALES)
                    .filter(([, config]) => !config.disabled)
                    .map(([code, { domain }]) => (
                        <link
                            key={code}
                            rel="alternate"
                            hrefLang={code}
                            href={`https://${domain}${window?.location?.pathname || ''}`}
                        />
                    ))}

                <meta
                    name="theme-color"
                    content="#ffffff"
                    media="(prefers-color-scheme: light)"
                />
                <meta
                    name="theme-color"
                    content="#000000"
                    media="(prefers-color-scheme: dark)"
                />
                <ColorSchemeScript />

                <link
                    rel="preconnect"
                    href="https://www.youtube.com"
                />
                <link
                    rel="dns-prefetch"
                    href="https://www.youtube.com"
                />

                {url && (
                    <Suspense fallback={null}>
                        <StructuredData
                            url={url}
                            translations={translations as Translations}
                        />
                    </Suspense>
                )}
            </head>
            <body className="min-h-screen bg-background">
                <ClientGlobalError
                    error={error}
                    translations={translations}
                />
            </body>
        </html>
    );
}
